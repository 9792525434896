const colors = {
  theme: "red-600",
  textWhite: "#fff",
  mainTextColor: "#EF6E16",  //orange
  black: "black",
  inputText: "#6B7280.",
  navHover: "#002147",
  backGroundColor: "#232429"
};

export default colors;